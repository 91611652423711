export const createSeriesOption = (data, settings) => {
  const {
    xColumn, yColumn, valueColumn, labelColumn,
  } = settings;

  const d = [];
  data.forEach((item) => {
    d.push([item[xColumn], item[valueColumn]]);
  });

  const CLUSTER_COUNT = 6;
  const DIENSION_CLUSTER_INDEX = 2;
  const COLOR_ALL = [
    '#37A2DA',
    '#e06343',
    '#37a354',
    '#b55dba',
    '#b5bd48',
    '#8378EA',
    '#96BFFF',
  ];

  const pieces = [];
  for (let i = 0; i < CLUSTER_COUNT; i++) {
    pieces.push({
      value: i,
      label: `Cluster ${i + 1}`,
      color: COLOR_ALL[i],
    });
  }
  // console.log('d', d);

  const option = {
    title: [
      {
        text: 'Displaying clusters of locations using average values of X (the known data) and Y (the data which you want to predict) for each location. Each point in the graph is a location and locations in the same cluster have been given the same colour.',
        left: 'center',
        top: 10,
        textStyle: {
          fontWeight: 'normal',
          fontSize: 20,
        },
      },
    ],
    dataset: [
      {
        source: d,
      },
      {
        transform: {
          type: 'ecStat:clustering',
          // print: true,
          config: {
            clusterCount: CLUSTER_COUNT,
            outputType: 'single',
            outputClusterIndexDimension: DIENSION_CLUSTER_INDEX,
          },
        },
      },
    ],
    tooltip: {
      position: 'top',
      formatter: (params) => {
        // console.log('params', params);
        const label = data[params.dataIndex][labelColumn];
        return `${label} - ${params.data[1]}, ${params.data[0]}}`;
      },
    },
    visualMap: {
      type: 'piecewise',
      top: 'middle',
      min: 0,
      max: CLUSTER_COUNT,
      left: 10,
      splitNumber: CLUSTER_COUNT,
      dimension: DIENSION_CLUSTER_INDEX,
      pieces,
    },
    grid: {
      left: 120,
    },
    xAxis: {
    },
    yAxis: {},
    series: {
      type: 'scatter',
      encode: { tooltip: [0, 1] },
      symbolSize: 15,
      itemStyle: {
        borderColor: '#555',
      },
      datasetIndex: 1,
    },
  };

  return option;
};
