<template>
  <div style="width: inherit; height: inherit">
    <loading
      v-show="loading"
      :message="'Loading data'"
    />
    <div
      v-show="!loading"
      ref="echart"
      v-resize="onResize"
      style="width: inherit; height: inherit"
    />
  </div>
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import * as ecStat from 'echarts-stat/dist/ecStat.js';
import ApiService from '../../../services/api.service';
import { createSeriesOption } from './lib/transformer';

import { createTrainingQuery } from '../../../lib/query-creator';

echarts.registerTransform(ecStat.transform.clustering);

const Loading = () => import('@/components/loading.vue');

export default {
  components: {
    Loading,
  },
  props: ['dataset', 'model'],
  data() {
    return {
      loading: false,
      chart: null,
    };
  },
  computed: {},
  mounted() {
    this.init();
    this.loadData();
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.echart);
    },
    draw(option) {
      // this.chart = echarts.init(this.$refs.echart);
      this.chart.setOption(option);
    },
    rendered() {},
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    async loadData() {
      this.$store.commit('mSetLoading', true);
      if (this.dataset && this.dataset.table_name) {
        this.loading = true;
        try {
          const appConfig = this.$store.state.appConfig;
          const model = this.model;
          const query = createTrainingQuery(model);

          const q = `SELECT "Location", avg(y) AS y, avg(x1) as x1 from (${query.sql}) s group by "Location"`;

          // console.log('this.dataset', this.dataset);
          const response = await ApiService.post('/raw_sql/', { q });
          const params = {
            xColumn: 'x1',
            yColumn: 'y',
            valueColumn: 'y',
            labelColumn: 'Location',
          };
          const data = response.data;
          const option = createSeriesOption(data, params);
          console.log('option', option);
          this.draw(option);
        } catch (error) {
          //
        }
        this.loading = false;
      }
      this.$store.commit('mSetLoading', false);
    },
  },
};
</script>
